import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import {
  CigarettebrandDto,
  SourceDto,
  transInfo,
} from 'src/entities/sourceDto';
import { CommonHttpClient } from 'src/service/common-httpclient.service';
import { config } from 'src/service/config';

@Component({
  selector: 'app-transportinfo',
  templateUrl: './transportinfo.component.html',
  styleUrls: ['./transportinfo.component.scss'],
})
export class TransportinfoComponent implements OnInit {
  source: SourceDto = null;
  transinfo: transInfo = null;
  cigarettebrandName: string;
  base_url = config.baseUrl;
  /* base_url = 'http://xmcw7c.natappfree.cc'; */
  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe((data: SourceDto) => {
      this.source = data;
    });
  }

  ngOnInit() {
    this.transinfo = {
      entryTime: this.source.entryTime,
      exitTime: this.source.entryTime,
      sortTime: this.source.sortTime,
      delayTime: this.source.delayTime,
      entrypath: 'Img/entry.jpg',
      exitpath: 'Img/entry.jpg',
      sortpath: 'Img/sort.jpg',
      delaypath: 'Img/delay.jpg',
    };
    this.cigarettebrandName=this.source.cigarettebrandName
    // this.activatedRoute.queryParams.subscribe((data: CigarettebrandDto) => {
    //   this.cigarettebrandName = data.cigarettebrandName;
    // });
  }

  //路由跳转到首页
  onsource() {
    history.back();
  }
  //获取当前时间
  getDate(item: any) {
    if (item) return moment(item, moment.ISO_8601).format('yyyy-MM-DD');
    return moment(new Date(), moment.ISO_8601).format('yyyy-MM-DD HH:mm:ss');
  }
  getPath(path: string) {
    if (path) {
      var pathary = path.split(';');
      if (pathary.length > 0) return pathary[0];
      else return path;
    }
  }
}
