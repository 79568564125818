import { Component, OnInit } from '@angular/core';
import { jsApiList } from 'src/entities/sourceDto';
import { ServiceService } from 'src/service/service.service';
@Component({
  selector: 'app-qrcode',
  templateUrl: './qrcode.component.html',
  styleUrls: ['./qrcode.component.scss'],
})
export class QrcodeComponent implements OnInit {
  config: jsApiList = null;
  constructor(private Service: ServiceService) {}

  ngOnInit() {}
}
