import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  authResult,
  CigarettebrandDto,
  jsApiList,
  SourceDto,
} from 'src/entities/sourceDto';
import { ServiceService } from 'src/service/service.service';
import * as moment from 'moment';
import { config } from 'src/service/config';
declare var wx: any;

@Component({
  selector: 'app-source',
  templateUrl: './source.component.html',
  styleUrls: ['./source.component.css'],
})
export class SourceComponent implements OnInit, AfterViewInit {
  QRcode: string;
  type: string = '1';
  qrURL: string;
  /* wx: any; */
  constructor(
    private router: Router,
    private Service: ServiceService,
    public route: ActivatedRoute
  ) {
    /*  this.getConfig(); */
  }
  source: SourceDto = null;
  cigarettebrands: CigarettebrandDto = null;
  authresult: authResult = null;
  base_url = config.baseUrl;
  /* base_url = 'http://xmcw7c.natappfree.cc'; */
  imagepath = '';
  code = '';
  Labelcode = '';
  opendid = '';
  config: jsApiList = null;
  message: string;
  showinfo = false;
  showerwei = false;
  latitude;
  longitude;
  ngOnInit() {
    /*  this.getDeviceById(); */
    this.getParams();
    this.getConfig();

    (<any>window).home = this;
  }
  ngAfterViewInit(): void {}

  getConfig() {
    this.Service.getConfig(location.href).subscribe((data) => {
      this.config = data;
      wx.config({
        debug: false,
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名
        jsApiList: ['scanQRCode', 'getLocation'], // 必填，需要使用的JS接口列表
        openTagList: ['wx-open-launch-weapp'],
      });
    });
    wx.ready(function () {
      if ((<any>window).home.code == '') {
        wx.scanQRCode({
          needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
          scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
          success: function (res) {
            var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
            (<any>window).home.Service.getCode(result).subscribe(
              (data: string) => {
                if (data) {
                  location.href = data;
                } else location.href = res.resultStr;
              }
            );
          },
        });
      }
      wx.getLocation({
        type: 'wgs84', // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
        success: function (res: { latitude: any; longitude: any }) {
          (<any>window).home.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
          (<any>window).home.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
          (<any>window).home.updateLocation();
        },
      });
      /* (<any>window).home.appTaginit(); */
    });
    wx.error(function (res: { errMsg: string }) {
      /* alert('微信验证出错了：' + res.errMsg); */
      //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
    });
  }

  //路由跳转到生产信息列表
  onlist() {
    this.router.navigate(['list']);
  }
  //路由跳转到生产详情列表
  ondetail() {
    this.router.navigate(['detail'], {
      queryParams: this.cigarettebrands,
    });
  }
  ontrans() {
    this.router.navigate(['trans'], {
      queryParams: this.source,
    });
  }
  goWxClick() {
    wx.scanQRCode({
      needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
      success: function (res) {
        var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        this.Service.getCode(result).subscribe((data: string) => {
          if (data) {
            location.href = data;
          } else location.href = res.resultStr;
        });
      },
    });
  }

  getDeviceById() {
    this.showinfo = false;
    /* const param = {
      code: 'SD',
      Labelcode: '5f16e371d86b2518',
      OpenID: 'oR7wc5rMg-xoYBCfuz08bhGzZmqw',
      PageType: '1',
      QRURL: 'http://q.scczc.net/51/SP/dmLBQElVX3',
    }; */
    const param = {
      code: this.code,
      Labelcode: this.Labelcode,
      OpenID: this.opendid,
    };
    this.Service.getSourceInfo(param).subscribe((data) => {
      this.showinfo = true;
      if (data.code == 0) {
        this.source = data.result;
        if (data.result != null) {
          this.cigarettebrands = data.result.cigarettebrands;
          if (data.result.path) {
            /* if (this.type == '2') { */
            var pathary = data.result.path.split(';');
            if (pathary.length > 0) this.imagepath = pathary[0];
            else this.imagepath = data.result.path;
            /* } else {
              var pathary = this.cigarettebrands.branch_Path.split(';');
              if (pathary.length > 0) this.imagepath = pathary[0];
              else this.imagepath = this.cigarettebrands.branch_Path;
            } */
          }
          /* setTimeout(() => {
            this.appTaginit();
          }, 1000); */
        } else {
          this.message = '未查询到相关信息';
        }
      } else {
        this.source = null;
        this.message = data.message;
      }
    });
  }
  updateLocation() {
    const param = {
      code: this.code,
      Labelcode: this.Labelcode,
      OpenID: this.opendid,
      latitude: this.latitude, // 纬度，浮点数，范围为90 ~ -90
      longitude: this.longitude,
    };
    this.Service.updateLocation(param).subscribe();
  }
  getDate(item: any) {
    if (item) return moment(item, moment.ISO_8601).format('yyyy-MM-DD');
    return moment(new Date(), moment.ISO_8601).format('yyyy-MM-DD HH:mm:ss');
  }

  complete(item: number) {
    if (item < 10) return '0' + item;
    return item;
  }
  getParams() {
    /* this.route.params.subscribe((res) => {
      console.log(res);
      if (location.search.slice(1)) {
        let info = location.search.slice(1).split('&');
        if (info.length > 1) {
          this.code = info[0].split('=')[1];
          this.Labelcode = info[1].split('=')[1];
          this.opendid = info[2].split('=')[1];
          this.type = info[3].split('=')[1];
          if (this.type == '2') this.qrURL = info[4].split('=')[1];
          this.getDeviceById();
        }
      }
    }); */ /* else this.goWxClick(); */
    if (location.search.slice(1)) {
      let info = location.search.slice(1).split('&');
      if (info.length > 1) {
        this.code = info[0].split('=')[1];
        this.Labelcode = info[1].split('=')[1];
        this.opendid = info[2].split('=')[1];
        this.getDeviceById();
      }
    }
  }
  /* appTaginit() {
    const wxOpenApp = document.getElementById('wxOpenInAPP') as Element;
    wxOpenApp.innerHTML =
      '<wx-open-launch-weapp id="launch-btn" username="gh_65023b469b1c" path="pages/loading/loading"  style="position:absolute;top:0;left:0;right:0;bottom:0;"><script type="text/wxtag-template"><div style="height: 100%;display: flex;flex-direction: column;justify-content: center;">更多活动</div></script></wx-open-launch-weapp>';
    const launchBtn = document.getElementById('launch-btn') as Element;
    if (!launchBtn) {
      return;
    }
    launchBtn.addEventListener('launch', function (e) {
      console.log('launch', e);
    });
    launchBtn.addEventListener('error', function (e: any) {
      console.log('fail', e.detail);
    });
  } */
  showerma() {
    this.showerwei = true;
  }
  closeerwei() {
    this.showerwei = false;
  }
}
