import { Component, NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { DetailComponent } from './detail/detail.component';
import { ListComponent } from './list/list.component';
import { QrcodeComponent } from './qrcode/qrcode.component';
import { SourceComponent } from './source/source.component';
import { TransportinfoComponent } from './transportinfo/transportinfo.component';

const routes: Routes = [
  { path: '', component: SourceComponent},
  { path: 'list', component: ListComponent },
  { path: 'detail', component: DetailComponent },
  { path: 'qrcode', component: QrcodeComponent },
  { path: 'trans', component: TransportinfoComponent },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
