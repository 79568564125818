import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CigarettebrandDto } from 'src/entities/sourceDto';
import * as moment from 'moment';

import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
} from "swiper";
import { config } from 'src/service/config';

SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller
]);

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
})
export class DetailComponent implements OnInit {
  //品牌图片数组
  PathSlides=[]
  //原料图片数组
  MaterialSlides=[];
  //生产图片数组
  ProductionSlides=[];
  //工艺图片数组
  TechnologySlides=[];
  //卷制图片数组
  RollingSlides=[];
  //存储图片数组
  StorageSlides=[];
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
  }
  datail: CigarettebrandDto = null;
  base_url = config.baseUrl;
  /* base_url = 'http://xmcw7c.natappfree.cc'; */
  
  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((data: CigarettebrandDto) => {
      this.datail = data;
    });

    //原料图片轮播的方法
    this.slideMaterial();
    //生产图片轮播的方法
    this.slideProduction();
    //工艺图片轮播的方法
    this.slideTechnology();
    //卷制图片轮播的方法
    this.slideRolling();
    //存储图片轮播的方法
    this.slideStorage();
  }
  
  //获取当前时间
  getDate() {
    return moment(new Date(), moment.ISO_8601).format('yyyy-MM-DD HH:mm:ss');
  }

  //路由跳转到首页
  onsource() {
    history.back();
  }

  getPath(path: string) {
    if (path) {
      var pathary = path.split(';');
      if (pathary.length > 0) return pathary[0];
      else return path;
    }
  }

  //原料图片轮播
  slideMaterial(){

    if(this.datail.material_path.indexOf(","))
    {
       this.MaterialSlides = this.datail.material_path.split(",");
       console.log(this.MaterialSlides)
    }
    else{
      this.MaterialSlides.push(this.datail.material_path)
    }
  }

  //生产图片轮播
  slideProduction(){

    if(this.datail.production_path.indexOf(","))
    {
       this.ProductionSlides = this.datail.production_path.split(",");
       console.log(this.ProductionSlides)
    }
    else
    {
       this.ProductionSlides.push(this.datail.production_path)
    }
  }

  //工艺图片轮播
  slideTechnology(){

    if(this.datail.technology_path.indexOf(","))
    {
       this.TechnologySlides = this.datail.technology_path.split(",");
       console.log(this.TechnologySlides)
    }
    else
    {
       this.TechnologySlides.push(this.datail.technology_path)
    }
  }

  //卷制图片轮播
  slideRolling(){

    if(this.datail.rolling_path.indexOf(","))
    {
       this.RollingSlides = this.datail.rolling_path.split(",");
       console.log(this.RollingSlides)
    }
    else
    {
       this.RollingSlides.push(this.datail.rolling_path)
    }
  }

  //存储图片轮播
  slideStorage(){

    if(this.datail.storage_path.indexOf(","))
    {
       this.StorageSlides = this.datail.storage_path.split(",");
       console.log(this.StorageSlides)
    }
    else
    {
       this.StorageSlides.push(this.datail.storage_path)
    }
  }
  
}
