import { Component, Host, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { retry } from 'rxjs/operators';
import { CigarettebrandDto } from 'src/entities/sourceDto';
import { config } from 'src/service/config';
import { ServiceService } from 'src/service/service.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.css'],
})
export class ListComponent implements OnInit {
  constructor(private router: Router, private Service: ServiceService) {}
  inputtext = '';
  list: CigarettebrandDto[] = [];
  base_url = config.baseUrl;
  /* base_url = 'http://xmcw7c.natappfree.cc'; */
  ngOnInit() {
    this.getAll();
  }

  //路由跳转到首页
  onsource() {
    /* this.router.navigate(['']) */
    history.go(-1);
  }
  search() {
    this.getAll();
  }
  getAll() {
    this.Service.getList(this.inputtext).subscribe((data) => {
      this.list = data;
    });
  }
  getPath(path: string) {
    if (path) {
      var pathary = path.split(';');
      if (pathary.length > 0) return pathary[0];
      else return path;
    }
  }
}
