import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  authResult,
  CigarettebrandDto,
  CodeResult,
  jsApiList,
  SourceDto,
  SourceResult,
} from 'src/entities/sourceDto';
import { CommonHttpClient } from './common-httpclient.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceService {
  private _commonhttp: CommonHttpClient;

  constructor(@Inject(CommonHttpClient) commonhttp: CommonHttpClient) {
    this._commonhttp = commonhttp;
  }

  getSourceInfo(params: any): Observable<SourceResult> {
    let url_ = '/api/services/app/Source/GetByInfo';
    return this._commonhttp.get(url_, params).pipe(
      map((data) => {
        const result = data.result;
        return result;
      })
    );
  }
  getList(params: any): Observable<CigarettebrandDto[]> {
    let url_ =
      '/api/services/app/CigaretteBrand/GetList' +
      (params ? '?input=' + params : '');
    return this._commonhttp.get(url_).pipe(
      map((data) => {
        const result = data.result;
        return result;
      })
    );
  }
  getConfig(params: string): Observable<jsApiList> {
    const param = {
      URL: params,
    };
    let url_ = '/api/services/app/Source/CreatWechatconfig';
    return this._commonhttp.post(url_, param).pipe(
      map((data) => {
        const result = data.result;
        return result;
      })
    );
  }
  getAuth(params: any): Observable<authResult> {
    let url_ = '/api/services/app/Source/GetAuth';
    return this._commonhttp.get(url_, params).pipe(
      map((data) => {
        const result = data.result;
        return result;
      })
    );
  }
  getCode(params: string): Observable<string> {
    let url_ = '/api/services/app/Source/GetCodeInfo?URL=' + params;
    return this._commonhttp.get(url_).pipe(
      map((data) => {
        const result = data.result;
        return result;
      })
    );
  }
  updateLocation(param: any) {
    let url_ = '/api/services/app/Source/UpdateLocation';
    return this._commonhttp.post(url_, param);
  }
}
